import { render, staticRenderFns } from "./PaymentInfo.vue?vue&type=template&id=161a9cfa&scoped=true&"
import script from "./PaymentInfo.vue?vue&type=script&lang=js&"
export * from "./PaymentInfo.vue?vue&type=script&lang=js&"
import style0 from "./PaymentInfo.vue?vue&type=style&index=0&id=161a9cfa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "161a9cfa",
  null
  
)

export default component.exports