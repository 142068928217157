<!--
 * @Author: huadan
 * @Date: 2021-04-20 13:22:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-11 14:35:52
 * @Description: 结算单
-->
<style lang="less" scoped>
.payment {
  .payment-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .logo {
      width: 84px;
      height: 42px;
      background-image: url('~@/assets/image/pactera-logo.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .title {
      font-size: 0;
      .date,
      .text {
        display: inline-block;
        vertical-align: middle;
      }
      .text {
        font-size: @font_size_6;
        color: @text_color_1;
        font-weight: bold;
      }
      .date {
        padding-left: 15px;
        font-size: @font_size_2;
        color: @text_color_2;
      }
    }
  }

  .info-item {
    display: flex;
    .label {
      flex: 0 0 85px;
      width: 85px;
      text-align: right;
      font-size: @font_size_2;
      color: @text_color_1;
    }
    .desc {
      flex: 1;
      margin-left: 10px;
      font-size: @font_size_2;
      color: @text_color_2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }

  .totalNum {
    display: inline-block;
    width: 80px;
    text-align: right;
  }
}
</style>

<template>
  <NeoDrawerLayout :showFooter="!isPdf" :useOriginalScroll="isPdf">
    <a-spin :spinning="loading" style="height: 100%">
      <div class="payment">
        <div class="payment-title">
          <div class="logo"></div>
          <div class="title">
            <span class="text">{{ $t('payment.drawer_payment') }}</span>
            <span class="date" v-if="ids.length <= 1">{{
              moment(order.updateDate).format($t('YYYY-MM-DD HH:mm')) || empty
            }}</span>
          </div>
          <div class="extra">
            <a-button v-if="!isPdf && isCanExport" type="primary" :loading="pdfLoading" @click="handleExport">
              {{ $t('export') }}
            </a-button>
            <a-button
              type="primary"
              v-if="!isPdf && !isCanExport"
              :disabled="true"
              :loading="pdfLoading"
              @click="handleExport"
            >
              {{ $t('export') }}
            </a-button>
            <NeoTag v-if="isPdf && ids.length < 2 && order.status !== 12" shape type="warning">{{
              drawerTitleTag
            }}</NeoTag>
            <NeoTag v-if="isPdf && ids.length < 2 && order.status === 12" shape type="success">{{
              drawerTitleTag
            }}</NeoTag>
          </div>
        </div>
        <!-- 结算单详情 -->
        <NeoTitle class="mb-3" :text="$t('payment.drawer_payment_detail')" />

        <a-row :gutter="20">
          <a-col :span="12" class="info-item mb-3" v-if="ids.length < 2">
            <span class="label">{{ $t('payment.order') }}:</span>
            <span class="desc" ref="syncHeight" style="display: inline-block">{{
              `${order.code || empty}/${order.name || empty}`
            }}</span>
          </a-col>
          <!-- 占空间的 -->
          <a-col
            :span="12"
            class="info-item mb-3"
            v-if="ids.length < 2"
            :style="{ opacity: !order.invoiceCode ? 0 : 1, height: syncHeight }"
          >
            <span class="label">{{ $t('payment.drawer_invoice_no') }}:</span>
            <span class="desc">{{ order.invoiceCode || empty }}</span>
          </a-col>

          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('payment.drawer_customer_entity') }}:</span>
            <span class="desc">{{ order.cusAgencyName || empty }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('payment.drawer_provider_entity') }}:</span>
            <span class="desc">{{ order.comAgencyName || empty }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('payment.drawer_address') }}:</span>
            <span class="desc">{{ order.cusAgencyAddress || empty }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('payment.drawer_address') }}:</span>
            <span class="desc">{{ order.comAgencyAddress || empty }}</span>
          </a-col>
          <!-- 单个结算单才有的字段：确认报价时间、确认终稿时间 -->
          <a-col v-if="ids.length === 1" :span="12" class="info-item mb-3">
            <span class="label">{{ $t('payment.drawer_confirm_quote_date') }}:</span>
            <span class="desc">{{ order.confirmQuoteDate | dateFormat }}</span>
          </a-col>
          <a-col v-if="ids.length === 1" :span="12" class="info-item mb-3">
            <span class="label">{{ $t('payment.drawer_confirm_final_draft_date') }}:</span>
            <span class="desc">{{ order.confirmFinalDraftDate | dateFormat }}</span>
          </a-col>
          <!-- <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('payment.drawer_contact') }}:</span>
            <a-tooltip placement="topLeft" v-show="showToolTip2">
              <template slot="title">
                <span>{{ `${order.cusManager || empty}（${order.cusManagerEmail || empty}）` }}</span>
              </template>
              <span class="desc">{{ `${order.cusManager || empty}（${order.cusManagerEmail || empty}）` }}</span>
            </a-tooltip>
            <span class="desc" v-show="!showToolTip" ref="emailcontent2">
              {{ `${order.cusManager || empty}（${order.cusManagerEmail || empty}）` }}
            </span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('payment.drawer_contact') }}:</span>
            <a-tooltip placement="topLeft" v-show="showToolTip">
              <template slot="title">
                <span>{{ `${order.comManager || empty}（${order.comManagerEmail || empty}）` }}</span>
              </template>
              <span class="desc">{{ `${order.comManager || empty}（${order.comManagerEmail || empty}）` }}</span>
            </a-tooltip>
            <span class="desc" v-show="!showToolTip" ref="emailcontent">
              {{ `${order.comManager || empty}（${order.comManagerEmail || empty}）` }}
            </span>
          </a-col> -->
        </a-row>
        <!-- 普通导出时候的表格 -->
        <a-table
          class="head-bg"
          size="middle"
          rowKey="id"
          :columns="columns"
          v-if="ids.length === 1"
          :data-source="dataSource"
          :pagination="false"
          :bordered="true"
        />
        <!-- 批量导出时候的表格 -->
        <a-table
          class="head-bg no-br"
          size="middle"
          rowKey="id"
          v-if="ids.length > 1"
          :scroll="!isPdf ? { x: 1200 } : {}"
          :data-source="dataSource"
          :pagination="false"
          :bordered="true"
        >
          <a-table-column
            key="code"
            :ellipsis="true"
            :title="$t('payment.order_num')"
            :width="!isPdf ? 100 : 70"
            :fixed="!isPdf ? 'left' : null"
          >
            <template slot-scope="text">
              <span :class="{ 'line-feed': isPdf }">{{ text.code | defined }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="name"
            :ellipsis="true"
            :title="$t('payment.order_name')"
            :width="!isPdf ? 170 : 100"
            :fixed="!isPdf ? 'left' : null"
          >
            <template slot-scope="text">
              <span class="line-feed" :title="text.name">{{ text.name | defined }}</span>
            </template>
          </a-table-column>

          <a-table-column key="date" :width="!isPdf ? 180 : 110" :ellipsis="true" :title="$t('payment.drawer_date')">
            <template slot-scope="text">
              <span :class="{ 'line-feed': isPdf }">{{
                moment(text.updateDate).format($t('dateFormat.a')) | defined
              }}</span>
            </template>
          </a-table-column>

          <a-table-column key="status" :width="!isPdf ? 180 : 90" :ellipsis="true" :title="$t('payment.drawer_status')">
            <template slot-scope="text">
              <span v-if="text.status === 12" :class="{ 'line-feed': isPdf }" class="success">{{
                localeDict.paymentStatus[text.settlementStatus]
              }}</span>
              <span v-else :class="{ 'line-feed': isPdf }" class="warning">{{
                localeDict.paymentStatus[text.settlementStatus]
              }}</span>
            </template>
          </a-table-column>

          <!-- 发票号 -->
          <a-table-column
            key="invoiceCode"
            :width="80"
            v-if="isPdf"
            :ellipsis="true"
            :title="$t('payment.drawer_invoice_no')"
          >
            <template slot-scope="text">
              <span :class="{ 'line-feed': isPdf }">{{ text.invoiceCode | defined }}</span>
            </template>
          </a-table-column>

          <!-- 确认报价时间 -->
          <a-table-column
            key="confirmQuoteDate"
            v-if="isPdf"
            :width="110"
            :ellipsis="true"
            :title="$t('payment.drawer_confirm_quote_date')"
          >
            <template slot-scope="text">
              <span :class="{ 'line-feed': isPdf }">{{ text.confirmQuoteDate | dateFormat }}</span>
            </template>
          </a-table-column>

          <!-- 确认终稿时间 -->
          <a-table-column
            key="confirmFinalDraftDate"
            v-if="isPdf"
            :width="110"
            :ellipsis="true"
            :title="$t('payment.drawer_confirm_final_draft_date')"
          >
            <template slot-scope="text">
              <span :class="{ 'line-feed': isPdf }">{{ text.confirmFinalDraftDate | dateFormat }}</span>
            </template>
          </a-table-column>

          <!-- 下单方经理 -->
          <a-table-column key="cusManager" :ellipsis="true" :title="$t('payment.customer_contacts')">
            <template slot-scope="text">
              <span :class="{ 'line-feed': isPdf }">{{ text.cusManager | defined }}</span>
            </template>
          </a-table-column>

          <!-- 供应商经理 -->
          <a-table-column key="comManager" :ellipsis="true" :title="$t('payment.provider_contacts')">
            <template slot-scope="text">
              <span :class="{ 'line-feed': isPdf }">{{ text.comManager | defined }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="quotePrice"
            align="right"
            :ellipsis="true"
            :width="!isPdf ? 120 : 100"
            :title="$t('payment.drawer_total_quotation')"
            :fixed="!isPdf ? 'right' : null"
          >
            <template slot-scope="text">
              <span v-if="currencyUnit()">{{ currencyUnit() }} </span>
              <span :class="{ 'line-feed': isPdf }">{{ numeral(text.quotePrice).format('0.[00]') }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="settlementPrice"
            align="right"
            :ellipsis="true"
            :width="!isPdf ? 120 : 100"
            :title="$t('payment.drawer_total_payment')"
            :fixed="!isPdf ? 'right' : null"
          >
            <template slot-scope="text">
              <div>
                <span v-if="currencyUnit()">{{ currencyUnit() }} </span>
                <!-- :value="numeral(text.settlementPrice).format('0.[00]')" -->
                <template v-if="($role('payment_customer') || $role('payment_customer_admin')) && isEdit">
                  <a-input
                    style="width: 70%"
                    :value="text.settlementPrice"
                    @change="settlementPriceChange($event, text)"
                  />
                </template>
                <template v-else>
                  <span :class="{ 'line-feed': isPdf }">{{ numeral(text.settlementPrice).format('0.[00]') }}</span>
                </template>
              </div>
            </template>
          </a-table-column>

          <template slot="footer">
            <a-row type="flex" align="middle" justify="space-between">
              <div v-html="$t('orderInfo.table_order_footer_count', { number: dataSource.length })"></div>
              <div>
                <span style="margin-right: 20px">
                  <span class="fs-3 c-1 warning">
                    <span v-if="currencyUnit()">{{ currencyUnit() }}</span
                    >{{ numeral(totalQuotePrice).format('0.[00]') }}</span
                  >
                </span>
                <span class="fs-3 primary">
                  <span v-if="currencyUnit()">{{ currencyUnit() }}</span
                  >{{ numeral(totalSettlementPrice).format('0.[00]') }}</span
                >
              </div>
            </a-row>
          </template>
        </a-table>
      </div>
    </a-spin>
    <!-- footer -->
    <template v-if="!isPdf" v-slot:footer>
      <a-space :size="10" class="fl-r">
        <!-- 拒绝结算v-if="$role('payment_provider')"-->
        <a-button
          v-if="isEdit && $role('payment_provider') && ids.length < 2 && order.status !== 11"
          class="neo-btn-primary"
          @click="handleRefuse"
        >
          {{ $t('payment.drawer_quotation_refuse') }}
        </a-button>
        <!-- 批准结算 -->
        <!-- <a-button v-if="isEdit" type="primary" @click="handleApprove">
          {{ $t('payment.drawer_quotation_approve') }}
        </a-button> -->
        <a-button v-if="isEdit" type="primary" @click="handleBatchClose">
          {{ $t('payment.drawer_quotation_approve') }}
        </a-button>
      </a-space>
    </template>
    <!-- 批量结算成功弹窗 -->
    <a-modal :title="$t('home.btn_account')" :visible="batchVisible">
      <h3 style="margin: 10px 0px 15px 60px">{{ $t('payment.quotation_batchinfo') }}</h3>
      <a-table class="no-br" size="middle" rowKey="id" :data-source="dataSource" :pagination="false" :bordered="true">
        <a-table-column key="id" :ellipsis="true" :title="$t('payment.order_num')">
          <template slot-scope="text">
            <span>{{ text.code }}</span>
          </template>
        </a-table-column>

        <a-table-column key="name" :ellipsis="true" :title="$t('payment.order_name')">
          <template slot-scope="text">
            <span>{{ text.name }}</span>
          </template>
        </a-table-column>

        <a-table-column key="quotePrice" align="right" :ellipsis="true" :title="$t('payment.drawer_total_quotation')">
          <template slot-scope="text">
            <span v-if="currencyUnit()">{{ currencyUnit() }}</span>
            <span>{{ text.quotePrice }}</span>
          </template>
        </a-table-column>

        <a-table-column
          key="settlementPrice"
          align="right"
          :ellipsis="true"
          :title="$t('payment.drawer_total_payment')"
        >
          <template slot-scope="text">
            <div>
              <span v-if="currencyUnit()">{{ currencyUnit() }}</span>
              <span>{{ text.settlementPrice }}</span>
            </div>
          </template>
        </a-table-column>
      </a-table>
      <template slot="footer">
        <a-button type="primary" @click="batchHandleOk">{{ $t('confirm') }}</a-button>
      </template>
    </a-modal>

    <!-- 批量关闭对话框 -->
    <a-modal
      :visible="questionVisible"
      class="question-table"
      :width="800"
      title="以下订单存在未关闭的问题，是否需要批量关闭？"
      @cancel="questionVisible = false"
      :centered="true"
      :closable="true"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCloseQuestion"> 不关闭且确认结算 </a-button>
        <a-button key="submit" type="primary" @click="handleConfirmQuestion"> 批量关闭且确认结算 </a-button>
      </template>
      <a-table
        class="head-bg striped no-border"
        rowKey="id"
        :data-source="questionData"
        :columns="questionColumns"
        :pagination="false"
      ></a-table>
    </a-modal>
  </NeoDrawerLayout>
</template>

<script>
import numeral from 'numeral'
import { Icon, Input } from 'ant-design-vue'
import NeoTag from '@/components/common/NeoTag'
import QuotationApproveMes from './QuotationApproveMes'
import moment from 'moment'
import Cookies from 'js-cookie'
import { downloadBlob } from '@/utils/utils'
const { storageKey, validate } = window.$g

export default {
  name: 'PaymentInfo',
  components: {
    NeoTag,
  },
  props: {
    // 展示方式是否为pdf（用于pdf导出）
    isPdf: {
      type: Boolean,
      default: false,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t('payment.drawer_quotation_detail'), //报价明细
          align: 'center',
          children: [
            {
              title: this.$t('payment.drawer_service_type'), //服务类型
              dataIndex: 'serviceType',
              key: 'serviceType',
              align: 'center',
              customRender: (text, row) => {
                return {
                  children: this.serviceTypeName(row),
                  // attrs: {
                  //   rowSpan: this.getRowSpan(`t_${row.serviceType}`, index),
                  // },
                }
              },
            },
            {
              title: this.$t('payment.drawer_source'), // 源语言
              dataIndex: 'sourceCode',
              key: 'sourceCode',
              align: 'center',
              customRender: (text, row) => {
                return {
                  children: this.sourceCodeName(row),
                  // attrs: {
                  // rowSpan: this.getRowSpan(`s_${row.serviceType}_${row.sourceCode}`, index),
                  // },
                }
              },
            },
            {
              title: this.$t('payment.drawer_target'), // 目标语言
              dataIndex: 'targetCode',
              key: 'targetCode',
              align: 'center',
              customRender: (text, row) => {
                return {
                  children: this.targetCodeName(row),
                }
              },
            },
            {
              title: this.$t('payment.drawer_price'), //单价
              dataIndex: 'price',
              key: 'price',
              align: 'center',
              customRender: (price, row) => {
                return {
                  children:
                    row.serviceType.split('-')[0] === '3'
                      ? `${this.currency(row)}${price}`
                      : `${this.currency(row)}${price} / ${this.saleUnit(row)}`,
                }
              },
            },
            {
              title: this.$t('payment.drawer_amount'),
              dataIndex: 'amount',
              key: 'amount',
              align: 'center',
              customRender: (amount) => {
                return {
                  children: amount,
                }
              },
            },
          ],
        },
        {
          title: this.$t('payment.drawer_quotation_count'), //结算统计
          dataIndex: 'quotePrice',
          key: 'quotePrice',
          align: 'center',
          width: 80,
          customRender: (quotePrice, row, index) => {
            return {
              children: (
                <span class="primary">
                  <span>{this.currency(row)} </span>
                  {this.numeral(quotePrice).format('0.[00]')}
                </span>
              ),
              attrs: {
                rowSpan: index === 0 ? this.dataSource.length : 0,
              },
            }
          },
        },
        {
          title: this.$t('payment.drawer_quotation_value'), //结算金额
          dataIndex: 'settlementPrice',
          key: 'settlementPrice',
          align: 'center',
          width: 120,
          customRender: (text, row, index) => {
            if (index === 0) {
              return {
                children: (
                  <div>
                    <span style="margin-right:2px">{this.currency(row)} </span>
                    {(this.$role('payment_customer') || this.$role('payment_customer_admin')) && this.isEdit ? (
                      <Input
                        size="small"
                        style="width: 70%"
                        value={numeral(this.order.settlementPrice).format('0.[00]')}
                        prop={row.settlementPrice}
                        v-on:rules={[this.validate.float_3, this.validate.required]}
                        onChange={({ target }) => {
                          row.settlementPrice = this.validateInput(target).value
                        }}
                      />
                    ) : (
                      <span>{numeral(this.order.settlementPrice).format('0.[00]')}</span>
                    )}
                  </div>
                ),
                attrs: {
                  rowSpan: this.dataSource.length,
                },
              }
            }
            return {
              attrs: {
                rowSpan: 0,
              },
            }
          },
        },
      ],
      localeDict: window.$g.localeDict,
      dataSource: [],
      moment,
      order: {},
      empty: '---',
      id: '',
      settlementPrice: [],
      fetchInfoObj: {},
      totalQuotePrice: null,
      totalSettlementPrice: null,
      batchVisible: false,
      pdfLoading: false,
      numeral,
      validate,
      showToolTip: false,
      showToolTip2: false,
      drawerTitleTag: null,
      currencyName: null,
      syncHeight: 0,
      isClose: 1,

      // 在线咨询问题表格
      questionVisible: false,
      questionData: [],
      questionColumns: [
        {
          title: '订单',
          dataIndex: 'orderCode',
          key: 'orderCode',
          width: 150,
          customRender: (text, record) => {
            return {
              children: '[' + text + ']' + record.orderName,
            }
          },
        },
        {
          title: '问题',
          dataIndex: 'questionContext',
          key: 'questionContext',
          customRender: (text) => {
            return {
              children: <div class="text-wrap">{text}</div>,
            }
          },
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 100,
          customRender: (text) => {
            return {
              children: (
                <span style={text == 1 ? 'color:#FB5549' : 'color:#53D478'}>{text == 1 ? '待回复' : '已回复'}</span>
              ),
            }
          },
        },
      ],
    }
  },
  created() {
    this.init()
    this.fetch()
  },
  mounted() {},
  watch: {
    orderName: {
      deep: true,
      handler(v) {
        this.$nextTick(() => {
          console.log('v :>> ', v)
          const offsetHeight = this.$refs.syncHeight?.offsetHeight
          const syncHeight = offsetHeight + 'px'
          syncHeight && (this.syncHeight = syncHeight)
        })
      },
    },
  },
  computed: {
    orderName() {
      return this.order.name
    },
    isCanExport() {
      if (this.ids.length < 2) {
        return this.order.status > 9
      } else {
        return this.dataSource.every((item) => item.status > 9)
      }
    },
  },
  methods: {
    init() {
      const ids = this.ids
      if (ids.length < 2) {
        // 单个结算单

        this.fetchInfoObj = {
          key: 'statementInfo',
          params: {
            id: ids[0],
          },
        }
      } else {
        // 多个结算单

        let _ids = ''
        ids.forEach((val, i) => {
          if (i == 0) {
            _ids = val
          } else {
            _ids += ',' + val
          }
        })
        this.fetchInfoObj = {
          key: 'batchStatementInfo',
          params: {
            ids: _ids,
          },
        }
      }
    },
    async fetch() {
      this.loading = true
      try {
        let data = await this.$http(this.fetchInfoObj)
        this.order = data
        if (this.ids.length < 2) {
          data.priceList[0].settlementPrice = data.settlementPrice
          data.priceList[0].quotePrice = data.quotePrice
          this.dataSource = data.priceList
        } else {
          this.dataSource = data.orderList
        }
        this.reComputedTotal()
        this.mockDataSource()
        if (this.ids.length < 2) {
          this.drawerTitleTag = this.localeDict.paymentStatus[this.order.settlementStatus]
        }
        this.loading = false
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    sourceCodeName(row) {
      // if (['1-1', '1-2'].some((val) => val === row.serviceType)) {
      if (row.sourceCode) {
        return this.$store.getters['app/getLangNameByCode'](row.sourceCode)
      } else {
        return this.empty
      }
    },
    serviceTypeName(row) {
      // return this.$store.getters['app/getDictLabel']('SERVICE_TYPE', row.serviceType)
      return row.serviceLabel
    },
    targetCodeName(row) {
      // if (['1-1', '1-2'].some((val) => val === row.serviceType)) {
      if (row.targetCode) {
        return this.$store.getters['app/getLangNameByCode'](row.targetCode)
      } else {
        return this.empty
      }
    },
    // 单个导出符号
    currency(row) {
      const res = this.$store.getters['app/getDictLabel']('CURRENCY', row?.currency) || ''
      return res.split(';')[1]
    },
    // 多个导出符号
    currencyUnit() {
      const res = this.$store.getters['app/getDictLabel']('CURRENCY', this.dataSource[0]?.currency) || ''
      return res.split(';')[1]
    },
    saleUnit(row) {
      return this.$store.getters['app/getDictLabel']('SALE_UNIT', row.unit)
    },
    validateInput(target) {
      let val = target.value
      try {
        val = val.replace(/[^\d.]/g, '') //清除“数字”和“.”以外的字符
        val = val.replace(/\.{2,}/g, '.') //只保留第一个. 清除多余的
        val = val.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
        if (val.indexOf('.') < 0 && val != '') {
          //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          val = parseFloat(val)
        }
        // 首字符不能是小数点
        if (val[0] === '.') {
          val = ''
        }
        target.value = val
        return target
      } catch (error) {
        console.log(error)
      }
    },
    settlementPriceChange({ target }, text) {
      const handledTargetVal = this.validateInput(target).value
      if (handledTargetVal[handledTargetVal.length - 1] !== '.') {
        text.settlementPrice = handledTargetVal
        this.reComputedTotal()
      }
    },
    handleInfoData(data) {
      if (this.ids.length < 2) {
        data.priceList.forEach((item) => {
          item.serviceType = this.$store.getters['app/getDictLabel']('SERVICE_TYPE', item.serviceType)
        })
      } else {
        let totalQuotePrice = 0
        let totalSettlementPrice = 0
        data.orderList.forEach((item) => {
          totalQuotePrice += item.quotePrice
          totalSettlementPrice += item.settlementPrice
        })
        this.totalQuotePrice = totalQuotePrice ?? ''
        this.totalSettlementPrice = totalSettlementPrice ?? ''
        return data.orderList
      }
    },
    isShowToolTip() {
      setTimeout(() => {
        const el = this.$refs.emailcontent
        const el2 = this.$refs.emailcontent2
        this.showToolTip = el.clientHeight < el.scrollHeight
        this.showToolTip2 = el2.clientHeight < el2.scrollHeight
      }, 1000)
    },

    reComputedTotal() {
      let totalQuotePrice = 0
      let totalSettlementPrice = 0
      const dataSource = JSON.parse(JSON.stringify(this.dataSource))
      dataSource.forEach((item) => {
        totalSettlementPrice += Number(item.settlementPrice)
        totalQuotePrice += Number(item.quotePrice)
      })
      this.totalQuotePrice = totalQuotePrice ?? ''
      this.totalSettlementPrice = totalSettlementPrice ?? ''
    },

    // mock 表格数据
    mockDataSource() {
      const dataSource = this.dataSource
      const _tableMergeMap = {}

      dataSource.forEach(({ serviceType, sourceCode }) => {
        console.error('qweqweqwe', { serviceType, sourceCode })
        // 计算服务类型
        let serviceTypeCount = _tableMergeMap[`t_${serviceType}`] || 0
        _tableMergeMap[`t_${serviceType}`] = serviceTypeCount + 1
        // 计算源语言
        if (this.$is.Defined(sourceCode)) {
          let sourceCount = _tableMergeMap[`s_${serviceType}_${sourceCode}`] || 0
          _tableMergeMap[`s_${serviceType}_${sourceCode}`] = sourceCount + 1
        } else {
          _tableMergeMap[`s_${serviceType}_${sourceCode}`] = 0
        }
      })
      this._tableMergeMap = _tableMergeMap
      this.dataSource = dataSource
    },

    // 获取表格合并
    getRowSpan(key, index) {
      if (index === 0) {
        return this._tableMergeMap[key] || 1
      } else {
        const prev = this.dataSource[index - 1]
        const cur = this.dataSource[index]
        if (/^t.*/.test(key)) {
          if (prev.serviceType === cur.serviceType) {
            return 0
          } else {
            return this._tableMergeMap[key] || 1
          }
        }
        if (/^s.*/.test(key)) {
          if (prev.serviceType === cur.serviceType && prev.sourceCode === cur.sourceCode) {
            return 0
          } else {
            return this._tableMergeMap[key] ?? 1
          }
        }
      }
    },
    // 拒绝结算
    async handleRefuse() {
      const that = this
      try {
        if (this.ids.length < 2) {
          await this.$http('rejectSettle', { id: this.ids[0] })
          this.$success({
            title: this.$t('payment.refuse_success'),
            icon: () => {},
            content: this.$t('payment.refuse_info', { name: this.order.name }),
            okText: this.$t('confirm'),
            onOk() {
              that.$emit('close')
              that.$emit('resetFetch')
            },
          })
        }
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    isDataEmpty() {
      const arr = ['', null, undefined]
      const result = this.dataSource.every((item) => arr.every((val) => val !== item.settlementPrice))
      return result
    },

    // 是否存在订单的问列表
    async handleBatchClose() {
      try {
        const data = await this.$http('unCloseQuestion', this.ids)
        this.questionData = data
        console.log('data :>> ', data)
        if (data.length > 0) {
          this.questionVisible = true
        } else {
          this.handleApprove()
        }
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    // 批量关闭且确认结算
    async handleConfirmQuestion() {
      this.isClose = 0
      await this.handleApprove()
      this.questionVisible = true
    },

    // 不关闭且确认结算
    async handleCloseQuestion() {
      this.isClose = 1
      await this.handleApprove()
      this.questionVisible = true
    },

    // 批准结算
    async handleApprove() {
      try {
        if (this.isEdit && !this.$role('payment_provider')) {
          if (this.ids.length < 2) {
            if (['', null, undefined].some((item) => item === this.dataSource[0].settlementPrice)) {
              return this.$message.warning(this.$t('payment.no_write_all_settle'))
            }
            if (this.dataSource[0].settlementPrice < 0) {
              return this.$message.warning(this.$t('payment.positive_number'))
            }
          } else {
            if (!this.isDataEmpty()) {
              return this.$message.warning(this.$t('payment.no_write_all_settle'))
            }
            if (this.dataSource.some((item) => item.settlementPrice < 0)) {
              return this.$message.warning(this.$t('payment.positive_number'))
            }
          }
        }
        const settlementPrice = this.getSettlementPrice()
        const onOk = () => {
          this.$emit('close')
          this.$emit('resetFetch')
          this.$emit('resetSelected')
        }
        const showSuccessModal = () => {
          this.$success({
            title: this.$t('payment.quotation_success'),
            icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
            content: (h) =>
              h(QuotationApproveMes, {
                props: {
                  name: this.$t('payment.quotation_info', { name: this.order.name }),
                  amountText: this.$t('payment.quote_amount'),
                  amount: `${this.currencyUnit()}${this.order.quotePrice ?? ''}`,
                  valueText: this.$t('payment.drawer_quotation_value'),
                  value: `${this.currencyUnit()}${settlementPrice[0] ?? ''}`,
                },
              }),
            okText: this.$t('confirm'),
            onOk() {
              onOk()
            },
            closable: false,
          })
        }
        if (this.$g.isSup) {
          const params = {
            ids: this.ids,
            isClose: this.isClose,
          }
          await this.$http('approveSettleSupply', params)
          if (this.ids.length < 2) {
            showSuccessModal()
          } else {
            this.batchVisible = true
          }
        } else if (this.$g.isCust) {
          if (!settlementPrice.length) {
            return this.$message.warning(this.$t('payment.nofilled_quote_price_tip'))
          }
          const params = this.getPriceApproveParam(settlementPrice)
          await this.$http('approveSettleClient', params)
          if (this.ids.length < 2) {
            showSuccessModal()
          } else {
            this.batchVisible = true
          }
        }
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    // 批量结算成功弹窗的确定事件
    batchHandleOk() {
      this.batchVisible = false
      this.$emit('close')
      this.$emit('resetFetch')
      this.$emit('resetSelected')
    },
    // 判断客户端要提交的结算金额是否有空
    getSettlementPrice() {
      if (this.ids.length < 2) {
        return this.dataSource[0].settlementPrice !== null ? [this.dataSource[0].settlementPrice] : []
      } else {
        const result = this.dataSource.map((item) => {
          return item.settlementPrice
        })
        for (const item of result) {
          if (['', null, undefined].includes(item)) {
            return []
          }
        }
        return result
      }
    },
    // 计算客户端要提交的价格结算的参数
    getPriceApproveParam() {
      let params = []

      if (this.ids.length < 2) {
        this.ids.forEach((id) => {
          params.push({
            id,
            isClose: this.isClose,
            settlementPrice: this.dataSource.find((_) => _.orderId === id)?.settlementPrice,
          })
        })
      } else {
        this.ids.forEach((id) => {
          params.push({
            id,
            isClose: this.isClose,
            settlementPrice: this.dataSource.find((_) => _.id === id)?.settlementPrice,
          })
        })
      }

      console.log('this.ids :>> ', this.ids)
      console.log('this.dataSource :>> ', this.dataSource)
      console.log('params :>> ', params)
      return params
    },
    // 导出结算单
    async handleExport() {
      let name
      if (this.ids.length <= 1) {
        name = `${this.order.name}_${this.$t('payment.drawer_payment')}`
      } else {
        name = `${this.order.cusAgencyName}_${this.$t('payment.drawer_payment')}_${moment().format('YYYYMMDDHHmm')}`
      }
      try {
        this.pdfLoading = true
        const pdf = await this.$http(
          'ossCmdHtmlToPdf',
          {
            oriUrl: 'pdf-payment',
            token: Cookies.get(storageKey.TOKEN),
            pdfInfoList: [{ ids: this.ids.length ? this.ids.join(',') : '', pdfName: '' }],
          },
          {
            responseType: 'blob',
          }
        )
        this.pdfLoading = false
        name = name + `.pdf`
        downloadBlob(name, 'application/pdf;charset-UTF-8', pdf)
      } catch (err) {
        this.pdfLoading = false
        this.$httpNotify(err)
      }
    },

    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find((item) => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.dataSource = dataSource
      }
    },
  },
}
</script>
